<template>
    <div v-cloak id="dashboardScreen">
        <div id="dashboardLabel">User Dashboard</div>
        <div><br></div>
        <!-- user is logging in anonymously so we show anonymous prof. pic-->
        <div v-if="user.user_profile_pic == null" v-bind="user" id="userProfilePicDash">
            <fa icon="user-circle"/>
        </div>
        <!-- otherwise we use their prof. pic from social media-->
        <div v-else v-bind="user">
            <img id="userProfilePicSG"
                class='profPicContainer'
                :src="user.user_profile_pic"
            >
        </div>
        <div v-bind="user" id="userFullNameDash">{{ user.user_full_name }}</div>
        <div><br></div>
        <div id="userScoreLabelDash"><b>Points</b></div>
        <div v-bind="user" id="userScoreDash">
            {{ round(user.user_score, 0) }}
        </div>
        <div><br></div>
        <div id="userAccuracyLabelSGS"><b>Skill</b></div>
        <div v-bind="user" id="userAccuracySGS">
            {{ round(user.user_skill, user.user_skill_significant_decimal) + "%" }}
        </div>
        <div><br></div>
        <div id="startGame">
            <button :class="{
                        'googleButton': user.user_authentication=='google',
                        'facebookButton': user.user_authentication=='facebook',
                        'twitterButton': user.user_authentication=='twitter',
                        'anonymousButton': user.user_authentication=='anonymous'
                        }"
                    @click="startGame()"
                    type="button">Play
            </button>
        </div>
        <div id="viewLeaderboards">
            <button :class="{
                        'googleButton': user.user_authentication=='google',
                        'facebookButton': user.user_authentication=='facebook',
                        'twitterButton': user.user_authentication=='twitter',
                        'anonymousButton': user.user_authentication=='anonymous'
                        }"
                    @click="viewLeaderboards()"
                    type="button">Leaderboard
            </button>
        </div>
        <div id="directionsPage">
            <button :class="{
                        'googleButton': user.user_authentication=='google',
                        'facebookButton': user.user_authentication=='facebook',
                        'twitterButton': user.user_authentication=='twitter',
                        'anonymousButton': user.user_authentication=='anonymous'
                        }"
                    @click="viewDirections()"
                    type="button">Tutorial
            </button>
        </div>
        <div id="logout">
            <button :class="{
                        'googleButton': user.user_authentication=='google',
                        'facebookButton': user.user_authentication=='facebook',
                        'twitterButton': user.user_authentication=='twitter',
                        'anonymousButton': user.user_authentication=='anonymous'
                        }"
                    @click="logout()"
                    type="button">Log Out
            </button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'Dashboard',
    computed: {
        ...mapState(['user']),
        ...mapState(['userNewsInteraction'])
    },
    methods: {
        round(number, precision) {
            return Number(Math.round(number + 'e' + precision) + 'e-' + precision)
        },
        startGame() {
            this.$router.push('/gamescreen')
            this.userNewsInteraction.totalPointsPossible = 0;
            this.userNewsInteraction.pointsReceived = 0;
            this.$store.commit('setUserNewsInteraction', this.userNewsInteraction)
        },
        viewLeaderboards() {
            this.$router.push('/leaderboards')
        },
        viewDirections() {
            this.$router.push('/directions')
            this.$store.commit("setTutorialReason", 'voluntary')
        },
        logout() {
            this.deleteUserCookie()
            // If the user is logged in using Facebook, we log them out explicitly,
            // as this is protocol
            if (this.user.user_authentication === 'facebook') {
                window.FB.logout(function (response) {
                // user is now logged out
                })
            }
            this.$router.push('/')
        },
        deleteUserCookie() {
            document.cookie = 'login_cookie=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;'
        }
    }
}
</script>

<style scoped>
    #dashboardScreen {
        text-align: center;
    }

    #dashboardLabel {
        color: #6A6969;
        font-size: 140%;
    }

    #userFullNameDash {
        font-size: 20px;
        font-style: italic;
    }

    .anonymousButton {
        width: 50%;
        height: 50px;
        margin: 3px;
        border: none;
        border-radius: 4px;
        background-color: #606060;
        color: white;
        font-size: 15px;
        font-weight: bold;
    }

    .anonymousButton:hover {
        width: 50%;
        height: 50px;
        margin: 3px;
        border: none;
        border-radius: 4px;
        background-color: #4d4d4d;
        color: white;
        font-size: 15px;
        font-weight: bold;
        cursor: pointer;
    }


    .facebookButton {
        width: 50%;
        height: 50px;
        margin: 3px;
        border: 2px solid;
        border-radius: 25px;
        background-color: #4267B2;
        color: white;
        font-size: 15px;
        font-style: bold;
    }

    .facebookButton:hover {
        width: 50%;
        height: 50px;
        margin: 3px;
        border: 2px solid;
        border-radius: 25px;
        background-color: #36538d;
        color: white;
        font-size: 15px;
        font-style: bold;
        cursor: pointer;
    }

    .googleButton {
        width: 50%;
        height: 50px;
        margin: 3px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #FFFFFF;
        color: #5F6368;
        font-size: 15px;
        font-weight: bold;
    }

    .googleButton:hover {
        width: 50%;
        height: 50px;
        margin: 3px;
        border: 1px solid #bdc1c6;
        border-radius: 4px;
        background-color: #f7f7f7;
        color: #5F6368;
        font-size: 15px;
        font-weight: bold;
        cursor: pointer;
    }

    .twitterButton {
        width: 50%;
        height: 50px;
        margin: 3px;
        border: 2px solid;
        border-radius: 25px;
        background-color: #1DA1F2;
        color: white;
        font-size: 15px;
        font-style: bold;
    }

    .twitterButton:hover {
        width: 50%;
        height: 50px;
        margin: 3px;
        border: 2px solid;
        border-radius: 25px;
        background-color: #1a88cc;
        color: white;
        font-size: 15px;
        font-style: bold;
        cursor: pointer;
    }

    .profPicContainer {
        height: 50px;
        width: 50px;
    }

    .profPicContainer img {
        max-height: 100%;
        max-width: 100%;
    }

    #userProfilePicDash {
        font-size: 5em;
    }

    [v-cloak] {
        display: none;
    }
</style>
