<template>
  <div id="loginScreen">
    <!-- <div id="welcomeLabel">Welcome to the Fake News Game!</div> -->
    <br />
    <div id="fakeNewsGameLogoDiv">
      <img
        id="fakeyLogoTransparentBackground"
        src="../../statics/FakeyLogoTransparentBackground.png"
        alt="Fakey"
      />
    </div>
    <div>
      <div id="loginPageButtonsDiv">
        <div id="g_id_onload"
          data-client_id="408353033691-ohe1i10hk1bfcrsh6vlot2dbn57g7jci"
          data-context="signin"
          data-ux_mode="popup"
          data-use_fedcm_for_prompt="true"
          data-login_uri="http://localhost:8000/auth/callback/"
          data-auto_prompt="false">
        </div>
        <button class="custom-google-login-button" @click="googleSignIn()">
          <img src="../../statics/google-logo.png" alt="Google Logo" />
          <span>Login with Google</span>
        </button>
        <!--<button class="custom-fb-login-button" @click="facebookSignIn()">
          <img src="../../statics/fb-logo.png" alt="Facebook Logo" />
          <span>Login with Facebook</span>
        </button>-->      
        <button class="custom-twitter-login-button" @click="twitterSignIn()">
          <img src="../../statics/twitter-logo.svg" alt="Twitter Logo" />
          <span>Login with Twitter</span>
        </button>
        <button class="custom-anonymous-login-button" @click="playAnonymously()">
          <img src="../../statics/anonymous-logo.svg" alt="Anonymous Icon" />
          <span>Play Anonymously</span>
        </button>
        <!-- <button id="instaSignIn" @click="instaSignIn()" type="button">
          Instagram Login
        </button>
        <button id="facebookSignIn" @click="facebookSignIn()" type="button">
          Facebook Login
        </button> -->
        <!-- <button id="googleSignIn" @click="googleSignIn()" type="button">
          Google Login
        </button> -->
        <!-- <button id="customGSignInBtn" @click="googleSignIn()" type="button">
          Google Login
        </button>
        <button id="twitterSignIn" @click="twitterSignIn()" type="button">
          Twitter Login
        </button>
        <button id="anonymousPlay" @click="playAnonymously()" type="button">
          Play Anonymously
        </button> -->
      </div>
    </div>
    
    <!-- Note: After every Android/iOS release, these values below must be changed -->
    <!-- <div>
      <button
        onclick="window.open('https://play.google.com/store/apps/details?id=com.cnets.fakey', '_blank')"
      >
        <span id="androidLogo">
          <i class="fa fa-android" aria-hidden="true"></i>
        </span>
        <span> Android </span>
      </button>
      <button
        onclick="window.open('https://itunes.apple.com/us/app/fakey-news/id1386410642?mt=8', '_blank')"
      >
        <span id="iosLogo">
          <i class="fa fa-apple" aria-hidden="true"></i>
        </span>
        <span> iOS </span>
      </button>
    </div> -->
  </div>
</template>

<script>
/* eslint-disable */

// Used for the login cookie process
import Sha256 from "sha256";
import axios from 'axios';
import { mapState } from 'vuex';
import facebookSdkMixin from "@/facebookSdkMixin";
// import googleSdkMixin from "@/googleSdkMixin";
import jwt_decode from "jwt-decode";

export default {
    // mixins: [facebookSdkMixin, googleSdkMixin],
    mixins: [facebookSdkMixin],
    name: 'Login',
    data() {
        return {
            fbSdkReady: false, //fb sdk is not ready
            // googleSdkReady: false, //google sdk is not ready
            authenticator_id: -1,
            user_auth: {
                authenticator_id: "-1",
                login_cookie: "",
                full_name: "",
                authentication: "",
                profile_pic: "",
                gender: "",
                age: "",
                location: "",
                followers: "",
                followees: "",
                user_score: 0,
                user_skill: 0,
                user_experience: 0,
            },
            seen_tutorial_first_time: false,
            showLoginButtons: false
        };
    },
    computed: {
        ...mapState(['user'])
    },
    methods: {
        round(number, precision) {
            return Number(Math.round(number + "e" + precision) + "e-" + precision);
        },
        loadGoogleSignInLibrary() {
          const script = document.createElement("script");
          script.src = "https://accounts.google.com/gsi/client";
          script.async = true;
          script.defer = true;
          script.onload = () => {
            // console.log("Google Sign-In library loaded");
          };
          document.head.appendChild(script);
        },
        checkGoogleSdkReady(callback) {
          if (typeof google !== "undefined" && google.accounts && google.accounts.id) {
            // console.log("Google SDK is ready")
            callback();
          } else {
            // console.log('Google SDK is not ready yet')
            setTimeout(() => {
              this.checkGoogleSdkReady(callback);
            }, 100);
          }
        },
        makeRandomSalt() {
            let characters =
                "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
            let salt = "";

            for (let char = 0; char < characters.length; char++) {
                salt += characters.charAt(
                Math.floor(Math.random() * characters.length)
                );
            }

            return salt;
        },
        deleteUserCookie() {
            document.cookie =
                "login_cookie=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;";
        },
        getTimestamp() {
            // Creating proper agnostic time to show to the user
            let currentdate = new Date();
            let timezoneOffset = currentdate.getTimezoneOffset() / 60;
            let timezoneSymbol = "+";

            if (timezoneOffset > 0) {
                timezoneSymbol = "-";
            }
            let dateTimeStamp =
                currentdate.getMonth() +
                1 +
                "/" +
                currentdate.getDate() +
                "/" +
                currentdate.getFullYear() +
                " at " +
                currentdate.getHours() +
                ":" +
                currentdate.getMinutes() +
                ":" +
                currentdate.getSeconds() +
                " UTC" +
                timezoneSymbol +
                currentdate.getTimezoneOffset() / 60 +
                ":00";
            return dateTimeStamp;
        },
        getSignificantDecimalSkill() {
            if (this.user.user_score > 100) {
                // Only relevant if score is greater than 100
                this.user.user_skill_significant_decimal = Math.floor(
                Math.log(this.user.user_score / 100.0) / Math.log(10)
                );
            }
        },
        getUserInfoOauthFacebook() {
          let vue = this

          // if (typeof window.FB === 'undefined') {
          //   setTimeout(() => {
          //     vue.getUserInfoOauthFacebook();
          //   }, 1000);
          //   return;
          // }
          FB.login(function (response) {
            if (response.status === 'connected') {
              vue.user_auth.authentication = 'facebook'
              // (*) Currently removing age and gender due to Facebook policy
              // Change, but may revisit, ask for more Oauth permissions,
              // and update later
              FB.api('/me?fields=id,name,picture', function(response) {
                vue.user_auth.authenticator_id = response.id.toString()
                // Cookie used for secure posting and authentication
                vue.user_auth.login_cookie = vue.storeUserInfoCookie()
                vue.user.login_cookie = vue.user_auth.login_cookie
                vue.user_auth.full_name = response.name.toString()
                vue.user_auth.profile_pic = response.picture.data.url.toString()
                // See (*) commenting below
                // vue.user_auth.gender = response.gender.toString()
                // vue.user_auth.age = response.age_range.min.toString()
                // Accessing the server to register the new user or update the user information
                vue.updateAndRetrieveUserInfo('facebook')
              })
              // Logged into your app and Facebook.
            } else {
              getUserInfoOauthFacebook();
              // Logged into your app and Facebook.
            }
          }, {scope: 'public_profile'})
        },
        getUserInfoOauthInsta() {
            OAuthWeb.OAuth.initialize(this.$twitterConfig)
            OAuthWeb.OAuth.setOAuthdURL('https://oauth.truthy.indiana.edu')
            let provider = 'instagram'
            let vue = this
            OAuthWeb.OAuth.popup(provider)
                .done(function (result) {
                  // console.log(result);
                  if ("access_token" in result) {
                    let instaResponse;
                    let access_token = result["access_token"]
                    let instaProfileUrl = "https://graph.instagram.com/v15.0/me?fields=id,username&access_token=" + access_token
                    fetch(instaProfileUrl)
                    .then((response) => response.json())
                    .then((json) => {
                      //console.log(json);
                      instaResponse = json;
                      // console.log(instaResponse);
                      vue.user_auth.authentication = 'facebook'
                      vue.user_auth.authenticator_id = instaResponse.id.toString()
                      // Cookie used for secure posting and authentication
                      vue.user_auth.login_cookie = vue.storeUserInfoCookie()
                      vue.user.login_cookie = vue.user_auth.login_cookie
                      vue.user_auth.full_name = instaResponse.username.toString()
                      // Default picture ref: https://commons.wikimedia.org/wiki/File:Default_pfp.jpg
                      vue.user_auth.profile_pic = "https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg"
                      // See (*) commenting below
                      // vue.user_auth.gender = response.gender.toString()
                      // vue.user_auth.age = response.age_range.min.toString()
                      // Accessing the server to register the new user or update the user information
                      vue.updateAndRetrieveUserInfo('facebook')
                    });
                    // console.log(vue);
                  }
                })
                .fail(function (err) {
                  // Logging error
                  vue.error.action = 'Insta login popup.'
                  vue.error.message = error.status.toString()
                  vue.error.details = error.statusText
                  vue.error.timestamp = vue.getTimestamp()
                  vue.$emit('previousScreen', vue.visibleGameScreen)
                  vue.$emit('errorOccurred', vue.error)
                  // Changing UI to the error screen
                  vue.visibleGameScreen = 'error'
                  vue.$emit('screenChanged', vue.visibleGameScreen)
                })
        },
        getUserInfoOauthGoogle() {
            OAuthWeb.OAuth.initialize(this.$twitterConfig);
            OAuthWeb.OAuth.setOAuthdURL("https://oauth.truthy.indiana.edu");
            let provider = "google";
            let vue = this;
            google.accounts.id.initialize({
              client_id: this.$googleConfig,
              callback: async function handleCredentialResponse(response) {
                // console.log("Encoded JWT ID token: " + response.credential);

                const idToken = response.credential;

                try {
                  const decodedToken = jwt_decode(idToken);
                  const userId = decodedToken.sub;
                  const name = decodedToken.name;
                  const profilePicture = decodedToken.picture;

                  const hashBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(userId));
                  const hashedUserId = Array.prototype.map.call(new Uint8Array(hashBuffer), x => ('00' + x.toString(16)).slice(-2)).join('');
                  const hashedUserIdBigInt = BigInt('0x' + hashedUserId);
                  const hashedUserIdReduced = Number(hashedUserIdBigInt % BigInt(2 ** 63 - 1));
                  vue.user_auth.authenticator_id = hashedUserIdReduced.toString();

                  // Cookie used for secure posting and authentication
                  vue.user_auth.login_cookie = vue.storeUserInfoCookie();
                  vue.user.login_cookie = vue.user_auth.login_cookie;
                  vue.user_auth.full_name = name;
                  vue.user_auth.profile_pic = profilePicture;

                  // Accessing the server to register the new user or update the user information
                  vue.updateAndRetrieveUserInfo(provider);

                } catch (error) {
                  console.error("Error decoding ID token:", error);
                  // Handle the error, e.g., show an error message or prompt the user to try again
                }
              }
            });
            var googleButton = document.querySelector('.custom-google-login-button');
            googleButton.style.display = 'none';
            var gIdOnloadDiv = document.querySelector('#g_id_onload');
            gIdOnloadDiv.style.display = 'block';
            google.accounts.id.renderButton(document.getElementById('g_id_onload'), {
              type: 'standard',
              shape: 'pill',
              theme: 'light',
              text: 'continue_with',
              size: 'large',
              logo_alignment: 'center',
              width: '200px',
              height: '70px'
            });
        },
        getUserInfoOauthTwitter() {
            // Connecting to Twitter OAuth (oauthio-web package and oauth cnets server)
            OAuthWeb.OAuth.initialize(this.$twitterConfig);
            OAuthWeb.OAuth.setOAuthdURL("https://oauth.truthy.indiana.edu");
            let provider = "twitter";
            let vue = this;
            OAuthWeb.OAuth.popup(provider)
                .done(function (result) {
                result
                    .me()
                    .done(function (response) {
                        vue.user_auth.authenticator_id = response.raw.id_str.toString();
                        // console.log('vue.user_auth.authenticator_id:')
                        // console.log(vue.user_auth.authenticator_id)
                        // Cookie used for secure posting and authentication
                        vue.user_auth.login_cookie = vue.storeUserInfoCookie();
                        vue.user.login_cookie = vue.user_auth.login_cookie;
                        vue.user_auth.full_name = response.raw.name.toString();
                        vue.user_auth.authentication = provider;
                        vue.user_auth.location = response.location.toString();
                        vue.user_auth.profile_pic = response.avatar.toString();

                        // ignoring followers and followees
                        // vue.user_auth.followers = response.raw.followers_count.toString();
                        // vue.user_auth.followees = response.raw.friends_count.toString();
                        
                        // Accessing the Server to register the new user or update the user information
                        vue.updateAndRetrieveUserInfo(provider);
                    })
                    .fail(function (err) {
                        // Logging error
                        vue.error.action =
                            "Retrieving user information from Twitter after successful Twitter login.";
                        vue.error.message = error.status.toString();
                        vue.error.details = error.statusText;
                        vue.error.timestamp = vue.getTimestamp();
                        this.$store.commit("setError", this.error)
                        this.$router.push('/error')
                    });
                })
                .fail(function (err) {
                    // Logging error
                    vue.error.action = "Twitter login popup.";
                    vue.error.message = error.status.toString();
                    vue.error.details = error.statusText;
                    vue.error.timestamp = vue.getTimestamp();
                    this.$store.commit("setError", this.error)
                    this.$router.push('/error')
                });
        },
        async updateAndRetrieveUserInfo(authentication) {
            try {
                let response = await axios({
                    method: "POST",
                    url: `${this.$databaseConfig}/usersignin/`,
                    data: this.user_auth
                });
                this.user.user_id = response.data.user_id;
                this.user.user_authentication = authentication;
                this.user.user_profile_pic = response.data.user_profile_pic;
                this.user.user_full_name = response.data.user_full_name;
                this.user.user_score = 10 * response.data.user_score;
                this.user.user_skill = response.data.user_skill;

                // But first we find the proper decimal place to show the user skill to reflect increase/decrease
                this.getSignificantDecimalSkill();
                // Changing UI to the next screen (the dashboard) as we have all the necessary info.
                this.$router.push('/dashboard');
            }
            catch(error) {
                // Logging error
                this.error.action =
                "On user game sign-in and user-specific data retrieval.";
                this.error.message = error.status.toString();
                this.error.details = error.statusText;
                this.error.timestamp = this.getTimestamp();
                this.$store.commit("setError", this.error)
                this.$router.push('/error')
            }
        },
        instaSignIn() {
            this.getUserInfoOauthInsta();
        },
        facebookSignIn() {
            // this.getUserInfoOauthFacebook();
            this.checkFbSdkReady(() => {
              this.getUserInfoOauthFacebook();
            });
        },
        googleSignIn() {
          this.checkGoogleSdkReady(() => {
            // console.log('google sdk ready');
            this.getUserInfoOauthGoogle();
          });
        },
        twitterSignIn() {
            this.getUserInfoOauthTwitter();
        },
        storeUserInfoCookie() {
            let currDate = new Date();
            // Cookie expires in roughly 3 months (90 days)
            currDate.setTime(currDate.getTime() + 90 * 24 * 60 * 60 * 1000);
            let cookieExpiration = "expires=" + currDate.toUTCString();
            let cookiePass = Sha256(currDate.toString() + this.makeRandomSalt());
            // Crafting cookie
            document.cookie =
                "login_cookie" + "=" + cookiePass + ";" + cookieExpiration + ";path=/;";
            // Returning cookie back to the user so that it can be stored on the server for the handshake
            return cookiePass;
        },
        retrieveUserInfoCookie() {
            let loginCookie = "login_cookie=";
            let cookieSplits = document.cookie.split(";");
            // Parsing through all parts of a cookie delimited by ;
            for (var i = 0; i < cookieSplits.length; i++) {
                let cookieSplit = cookieSplits[i];
                // Remove whitespace
                while (cookieSplit.charAt(0) === " ") {
                cookieSplit = cookieSplit.substring(1);
                }
                // Login cookie found
                if (cookieSplit.indexOf(loginCookie) === 0) {
                    return cookieSplit.substring(loginCookie.length, cookieSplit.length);
                }
            }
            // Login cookie not present
            return "";
        },
        async playAnonymously() {
            // If the user already has their session information via cookie
            if (
                this.user.user_authentication === "anonymous" &&
                (this.user.user_score || this.user.user_skill)
            ) {
                // Changing UI to the next screen (the dashboard) as we have all the necessary info.
                this.$router.push('/dashboard');
                // this.visibleGameScreen = "dashboard";
                // this.$emit("screenChanged", this.visibleGameScreen);
            } else {
                // User does not have their session information so creating anonymous
                // request to get new cookie
                this.user_auth.authentication = "anonymous";
                this.user_auth.login_cookie = this.storeUserInfoCookie();
                this.user.login_cookie = this.user_auth.login_cookie;
                
                try {
                    let response = await axios({
                        method: 'POST',
                        url: `${this.$databaseConfig}/usersignin/`,
                        data: this.user_auth
                    });
                    this.user.user_id = response.data.user_id;
                    this.user.user_authentication = this.user_auth.authentication;
                    this.user.user_profile_pic = response.data.user_profile_pic;
                    this.user.user_full_name = response.data.user_full_name;
                    this.user.user_score = 10 * response.data.user_score;
                    this.user.user_skill = this.round(response.data.user_skill, 0);

                    // But first we find the proper decimal place to show the user skill to reflect increase/decrease
                    this.getSignificantDecimalSkill();
                    // Changing UI to the next screen (the dashboard) as we have all the necessary info.
                    this.$router.push('/dashboard');
                }
                catch(error) {
                    // Logging error
                    this.error.action =
                        "Posting and retrieving information for anonymous user.";
                    this.error.message = error.status.toString();
                    this.error.details = error.statusText;
                    this.error.timestamp = this.getTimestamp();
                    this.$store.commit("setError", this.error)
                    this.$router.push('/error')
                }
            }
        },
        async attemptCookieLogin() {
            let userInfoCookie = this.retrieveUserInfoCookie();
            if (userInfoCookie !== "") {
                let user_get_request = { login_cookie: userInfoCookie };
                let vm = this;
                // Storing the user cookie as an element in the DOM for future usage given the user
                vm.user.login_cookie = userInfoCookie;

                try {
                    let response =  await axios({
                        method: 'POST',
                        url: `${this.$databaseConfig}/getuserinfofromcookie/`,
                        data: user_get_request
                    })
                    vm.user.user_id = response.data.user_id;
                    vm.user.user_authentication = response.data.authentication;
                    vm.user.user_profile_pic = response.data.user_profile_pic;
                    vm.user.user_full_name = response.data.user_full_name;
                    vm.user.user_score = 10 * response.data.user_score;
                    vm.user.user_skill = response.data.user_skill;

                    if (vm.user.user_score > 0) {
                        // First we find the proper decimal place to show the user skill to reflect increase/decrease
                        vm.getSignificantDecimalSkill();
                    }

                    if (
                        vm.user.user_authentication === "twitter"
                    ) {
                        // Changing UI to the next screen (the dashboard) as we have all the necessary info.
                        this.$router.push('/dashboard');
                    } else if (vm.user.user_authentication === "anonymous") {
                        // User should not see the leaderboards screen as they are an anonymous user, so they go straight to the game
                        this.$router.push('/login');
                    }
                }
                catch(error) {
                    // Cookie no longer valid so we delete previous cookie and render directions screen
                    vm.deleteUserCookie();
                    this.user.seen_tutorial_first_time = true;
                    this.$store.commit('setTutorialReason', 'newUser')
                    // Go to tutorial screen
                    this.$router.push('/directions')
                }
                return true;
            } else {
                // Return used to render tutorial screen as user cookie is expired or not available
                return false;
            }
        },
    },
    mounted() {
        // In case anonymous user tries to create an account, we keep their score from their previous game session
        if (this.user.user_authentication == "anonymous") {
            this.user_auth.user_score = this.user.user_score / 10.0;
            this.user_auth.user_experience = this.user.user_experience;
            this.user_auth.user_skill = this.user.user_skill;
        }

        // Attempt to see if user is logged in via cookie and if the user is not, we show them the tutorial

        if (!this.attemptCookieLogin() && !this.user.seen_tutorial_first_time) {
            this.user.seen_tutorial_first_time = true;
            this.$store.commit('setTutorialReason', 'newUser');
            // Go to tutorial screen
            this.$router.push('/directions')
        }

        //google
        this.loadGoogleSignInLibrary();
    },
};
</script>

<style scoped>
button {
  margin: 1%;
}

#androidLogo {
  color: green;
}

#iosLogo {
  color: #6A6969;
}

#welcomeLabel {
  color: #6A6969;
  font-size: 140%;
}

#loginScreen {
  text-align: center;
}

#loginDiv {
  margin-left: 10%;
  margin-right: 10%;
  text-align: left;
}

#appDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#appDescription button {
  background-color: #0364CC;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

#appDescription button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

#loginPageButtonsDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
  max-width: 20em;
}

#loginPageButtonsDiv button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  border: 2px solid;
  border-radius: 25px;
  font-size: 20px;
  font-style: bold;
  cursor: pointer;
  margin-bottom: 10px;
}

#loginPageButtonsDiv button img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

#loginPageButtonsDiv button span {
  flex: 1;
  text-align: center;
}

.custom-fb-login-button {
    display: inline-flex;
    align-items: center;
    background-color: #1877F2;
    border-radius: 4px;
    border: none;
    color: white;
    font-size: 20px;
    font-weight: bold;
    padding: 8px 16px;
    cursor: pointer;
    text-decoration: none;
    margin-top: 5px;
}
.custom-fb-login-button:hover {
  background-color: #1468d6;
}
.custom-fb-login-button span {
  margin-left: 8px;
}
.custom-fb-login-button img {
  height: 20px;
  width: auto;
}

.custom-google-login-button {
  display: inline-flex;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #5F6368;
  font-size: 20px;
  font-weight: bold;
  padding: 8px 16px;
  cursor: pointer;
  text-decoration: none;
  margin-top: 5px;
}
.custom-google-login-button:hover {
  background-color: #f7f7f7;
  border: 1px solid #bdc1c6;
}
.custom-google-login-button span {
  margin-left: 8px;
}
.custom-google-login-button img {
  height: 20px;
  width: auto;
}


.custom-twitter-login-button {
  display: inline-flex;
  align-items: center;
  background-color: #1DA1F2;
  border-radius: 4px;
  border: none;
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 8px 16px;
  cursor: pointer;
  text-decoration: none;
  margin-top: 5px;
}
.custom-twitter-login-button:hover {
  background-color: #1994de;
}
.custom-twitter-login-button span {
  margin-left: 8px;
}
.custom-twitter-login-button img {
  height: 20px;
  width: auto;
}

.custom-anonymous-login-button {
  display: inline-flex;
  align-items: center;
  background-color: #606060;
  border-radius: 4px;
  border: none;
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 8px 16px;
  cursor: pointer;
  text-decoration: none;
  margin-top: 5px;
}
.custom-anonymous-login-button:hover {
  background-color: #4d4d4d;
}
.custom-anonymous-login-button span {
  margin-left: 8px;
}
.custom-anonymous-login-button img {
  height: 20px;
  width: auto;
}

#fakeyLogoTransparentBackground {
  border-style: none;
  /* border-color: black;
    border-width: 1px; */
  height: 200px;
  width: 200px;
}

#disclaimerMessage {
  background-color: yellow;
  font-size: 70%;
}
</style>
