<template>
    <div id="fake-news-game-app">
        <PWABanner />
        <router-view></router-view>
        <Footer />
        <!-- <div class="footer">
            <a href="#" @click.stop.prevent="goToPrivacy()">Privacy Policy</a>
        </div> -->
    </div>
</template>

<script>
/*
 * Root component
 */
import PWABanner from './components/PWABanner.vue'
import Footer from './components/footer/Footer.vue';

export default {
    name: 'App',
    components: {
        PWABanner,
        Footer
    }
}
</script>

<style>
body {
    background-color: #E9EBEE;
}

html {
    scroll-behavior: smooth;
}

a {
    color: #0364CC;
}

a:hover {
    color: #0253AA;
}

@media only screen and (min-width: 500px) {
    body {
        width: 500px;
        text-align: center;
        margin: auto;
    }
}
</style>
